import React from 'react';
import PropTypes from 'prop-types';
import ManufacturerEdit from './ManufacturerEdit';
import ManufacturerRemove from './ManufacturerRemove';
import List from '../../general/List';
import withDataHOC from '../../dataProvider/withDataHOC';
import {GLOBAL_DATA} from '../../../constants/globalData';
import {Manufacturers} from '../../../constants/propTypesDefinitions';

/**
 * @fero
 */

class ManufacturersList extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            selectedManufacturerId: null,
        }
    }

    static propTypes = {
        [GLOBAL_DATA.MANUFACTURERS]: Manufacturers.isRequired,
    };

    onSelectManufacturer = (manufacturerId) => {
        this.setState({
            selectedManufacturerId: manufacturerId,
        })
    };

    render() {
        const {selectedManufacturerId} = this.state;
        const manufacturers = this.props[GLOBAL_DATA.MANUFACTURERS];
        return <List
                values={manufacturers}
                onItemClick={(value) => {
                    this.onSelectManufacturer(value.id);
                }}
                selectedValueId={selectedManufacturerId}
            >
                {manufacturers.map((manufacturer) => {
                    return <div key={manufacturer.id} className="d-flex flex-wrap align-items-center">
                        {manufacturer.name}
                        {manufacturer.id == selectedManufacturerId ? [
                            <ManufacturerEdit
                                key="edit"
                                manufacturer={manufacturer}
                            />,
                            < ManufacturerRemove
                                key="remove"
                                manufacturer={manufacturer}
                            />
                        ] : null}
                    </div>
                })}
        </List>;
    }

}

export default withDataHOC([GLOBAL_DATA.MANUFACTURERS])(ManufacturersList);